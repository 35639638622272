// Brand Colors
$altera-primary: #acc9b2;
$altera-dark: #6c9675;
$altera-pastel: #e1fce653;
$base-primary: #323d34;
$color-secondary: #ffffff;
$light-primary: #ffffff;

// Greys
$grey-primary: #6d6d6d91;
$grey-secondary: #f4f4f4;
$grey-dark: #383d3d91;
$light-grey: #dcdcdc91;
$light-grey2: rgb(106, 106, 106);

// Button Colors
$button-shadow: rgba(0, 0, 0, 0.1);

// Pastels
$pastel-1: #d3fae7;
$pastel-2: #e9f0fe;
$pastel-3: #fdd4da;
$pastel-4: #fae7d3;
$pastel-5: #e7d3fa;
$pastel-6: #d3fafd;
