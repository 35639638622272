@import './settings/colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

html {
  font-size: 62.5%;
}

* {
  color: $base-primary;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $color-secondary;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

h1 {
  font-weight: 700;
}

button {
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}
