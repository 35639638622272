@import '../../styles/settings/settings.scss';

.main {
  margin-top: 12rem;
  margin-bottom: 4rem;
  h1 {
    font-size: 3.2rem;
  }

  &_container {
    display: flex;
    flex-direction: column;
  }

  // TOP SECTION
  &_top {
    margin-bottom: 8rem;
    padding-top: 4rem;
    .btn1 {
      img {
        margin-bottom: -0.2rem;
        width: 11rem;
      }
    }

    .btn2 {
      cursor: pointer;
      @include btn-base1;
      background-color: $light-primary;
      box-shadow:
        -0.05rem 0.05rem 0.4rem rgba(0, 0, 0, 0.1),
        inset -0.05rem -0.05rem 0.16rem rgba(176, 176, 176, 0.1);
      border-radius: 20rem;
      color: $altera-primary;
      width: fit-content;
      display: inline-block;
    }
  }

  // MIDDLE SECTION
  &_middle {
    margin-bottom: 8rem;
    a {
      text-decoration: underline;
      &:hover {
        color: $altera-dark;
      }
    }
    p {
      font-size: 2.2rem;
      font-weight: 600;
    }
  }

  // BOTTOM SECTION
  &_bottom {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    margin-bottom: 8rem;
    h1 {
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    &_firstSection {
      p {
        font-size: 2rem;
      }
      &_paragraph {
        margin-bottom: 2rem;
        margin: 1.5rem 0;
      }
    }

    &_last {
      &_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        &_unit {
          background-color: $grey-secondary;
          border-radius: 0.8rem;
          padding: 1rem;
          &_name {
            display: flex;
            flex-direction: column;
            margin-bottom: 0.6rem;
            h2 {
              font-size: 1.6rem;
              margin-bottom: 0.4rem;
            }
            p {
              font-size: 1.2rem;
              padding: 0.2rem 0.8rem;
              border-radius: 4rem 10rem 10rem 4rem;
              width: fit-content;
              margin-bottom: 0.4rem;
            }
            .pastel1 {
              background-color: $pastel-1;
            }
            .pastel2 {
              background-color: $pastel-2;
            }
            .pastel3 {
              background-color: $pastel-3;
            }
            .pastel4 {
              background-color: $pastel-4;
            }
            .pastel5 {
              background-color: $pastel-5;
            }
            .pastel6 {
              background-color: $pastel-6;
            }
          }
          &_info {
            font-size: 1.25rem;
          }
        }
      }
    }
  }

  .announcement-banner {
    max-width: 100rem;
    margin: 8rem auto;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3rem;
    padding: 8rem 4rem;
    text-align: center;
    position: relative;
    overflow: hidden;

    &::before {
      display: none;
    }

    p {
      font-size: 3rem;
      line-height: 1.4;
      color: #111827;
      margin: 0;
      font-weight: 600;
      letter-spacing: -0.03em;
      -webkit-text-fill-color: initial;
      background: none;
      -webkit-background-clip: initial;
      text-shadow: none;
    }
  }

  // Add new blogs section styles
  &_blogs_section {
    margin: 4rem 0 8rem 0;
    width: 100%;
  }
}

// Mobile compatability
@include respond-to('desktop') {
  .main {
    margin-top: 10%;
    h1 {
      font-size: 2.4rem;
    }

    &_container {
      gap: 5rem;
    }

    &_top {
      .btn1 {
        img {
          width: 7.8rem;
        }
      }
    }

    &_middle {
      padding-right: 2rem;
      p {
        font-size: 1.8rem;
      }
    }

    &_bottom {
      h1 {
        font-size: 2rem;
      }
      &_firstSection {
        padding-right: 2rem;
        p {
          font-size: 1.6rem;
        }
        &_paragraph {
          margin-bottom: 1.6rem;
          margin: 1.5rem 0;
        }
      }

      &_last {
        margin-right: -20rem;
      }
    }
  }
}

@include respond-to('tablet') {
  .main {
    margin-top: 24%;

    &_bottom {
      &_last {
        margin-right: 0rem;
        &_grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .announcement-banner {
      margin-bottom: 6rem;
      p {
        font-size: 2.2rem;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}

@include respond-to('mobile') {
  .main {
    margin-top: 20%;

    .announcement-banner {
      margin-top: 16rem;
      margin-bottom: 4rem;
      padding: 5rem 3rem;
      p {
        font-size: 1.6rem;
      }
    }

    &_top {
      margin-bottom: 2rem;
      
      span {
        font-size: 1.8rem;
        line-height: 1.3;
      }
    }

    &_middle {
      p {
        font-size: 1.4rem;
      }
    }

    &_middle,
    &_blogs_section,
    &_bottom {
      margin-bottom: 2rem;
    }

    &_bottom {
      gap: 6rem;
      
      h1 {
        font-size: 1.8rem;
      }
      
      &_firstSection {
        p {
          font-size: 1.4rem;
        }
      }

      &_last {
        &_grid {
          &_unit {
            padding: 0.8rem;

            &_name {
              h2 {
                font-size: 1.2rem;
              }
              p {
                font-size: 0.9rem;
              }
            }

            &_info {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
