@import '../../styles/settings/settings.scss';

.footer {
  p {
    font-size: 1.4rem;
    color: $light-grey2;
    font-weight: 700;
  }
  a {
    font-size: 1.5rem;
    font-weight: 500;
    &:hover {
      color: $altera-dark;
      text-decoration: underline;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  div {
    display: flex;
    gap: 2.4rem;
    flex-wrap: wrap;
  }
}

@include respond-to('tablet') {
  .footer {
    &-content {
      justify-content: left;
    }
  }
}

@include respond-to('mobile') {
  .footer {
    div {
      gap: 1rem;
    }
  }
}
