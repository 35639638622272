@import './settings/settings.scss';

.app-container {
  background: linear-gradient(
    100deg,
    rgba(255, 245, 235, 0.3),
    rgba(255, 245, 235, 0.3),
    rgba(255, 240, 230, 0.3),
    rgba(255, 240, 230, 0.3),
    rgba(255, 240, 230, 0.3)
  );
  min-height: 100vh;
  margin: 0;
  padding: 2.4rem;
  padding-top: 10rem;
  border-radius: 0;
}

.app-wrapper {
  padding: 1rem;
  width: 60%;
  margin: auto;
}

// Mobile compatability
@include respond-to('desktop') {
  .app-wrapper {
    width: 65%;
  }
}

@include respond-to('tablet') {
  .app-container {
    padding: 1.6rem;
  }

  .app-wrapper {
    width: 75%;
    padding: 0.8rem;
  }
}

@include respond-to('mobile') {
  .app-container {
    padding: 1.2rem;
  }

  .app-wrapper {
    width: 95%;
    padding: 0.8rem;
  }
}
