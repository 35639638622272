.dropdown-container {
  padding: 1.6rem 2.4rem;
  border: 0.1rem solid $grey-primary;
  border-radius: 1.6rem;
}

.dropdown-section {
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;

    h1 {
      span {
        font-size: 1.6rem;
      }
      margin: 0;
      font-size: 2rem;
      font-weight: 500;
    }
  }

  .dropdown-arrow {
    font-size: 1.6rem;
  }
}

@include respond-to('tablet') {
  .dropdown-section {
    .dropdown-header {
      h1 {
        font-size: 1.8rem;
        span {
          font-size: 1.4rem;
        }
      }
    }
  }

  .dropdown-arrow {
    font-size: 1.2rem;
  }
}
