@import '../../styles/settings/settings.scss';

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &--floating {
    position: fixed;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  &_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 4rem;
    max-width: 1920px;
    margin: 0 auto;
  }

  &_left {
    .header_logo {
      img {
        width: 11rem;
        cursor: pointer;
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &_nav {
    display: flex;
    gap: 2rem;
    align-items: center;

    a {
      font-size: 1.4rem;
      color: $base-primary;
      font-weight: 500;
      transition: color 0.2s ease;

      &:hover {
        color: $altera-dark;
      }
    }
  }

  &_cta {
    button {
      display: flex;
      align-items: center;
      gap: 1rem;
      border-radius: 20rem;
      padding: 0.6rem 1.4rem;
      background: linear-gradient(360deg, #6c9675, 40%, #acc9b2 100%);
      transition: all 0.2s ease;

      p,
      svg {
        color: $color-secondary !important;
        font-size: 1.4rem;
      }

      p {
        font-weight: 500;
      }

      &:hover {
        box-shadow: 0 2px 8px rgba(42, 196, 121, 0.3);
      }
    }
  }
}

@include respond-to('tablet') {
  .header {
    &_content {
      padding: 1.6rem;
    }

    &_nav {
      display: none;
    }

    &_logo img {
      width: 9rem;
    }
  }
}

@include respond-to('mobile') {
  .header {
    &_content {
      padding: 1.2rem;
    }

    &_left {
      .header_logo {
        img {
          width: 7rem;
        }
      }
    }

    &_cta {
      button {
        padding: 0.5rem 1rem;

        p {
          font-size: 1.3rem;
        }
      }
    }
  }
}
