@import '../../styles/settings/settings.scss';

.blogs {
  h3 {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
    color: $light-grey2;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  &_navigation {
    display: flex;
    gap: 1rem;
  }

  &_wrapper {
    position: relative;
  }

  &_nav {
    background: $grey-secondary;
    border: 1px solid $light-grey;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;

    &:hover {
      background: $altera-primary;
      border-color: $altera-primary;
      
      svg {
        path {
          color: $color-secondary;
        }
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      
      &:hover {
        background: $grey-secondary;
        border-color: $light-grey;
        
        svg path {
          color: $base-primary;
        }
      }
    }

    svg {
      font-size: 1.6rem;
      path {
        color: $base-primary;
      }
    }
  }

  &_container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 0.95fr));
    gap: 5rem;
    width: 100%;
  }

  &_card {
    background-color: $color-secondary;
    padding: 1.6rem;
    border-radius: 1.2rem;
    cursor: pointer;
    border: 0.2rem solid $grey-secondary;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      border-radius: 1rem;
      display: block;
      margin: auto;
      margin-bottom: 1.6rem;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    h4 {
      font-size: 1.6rem;
      margin-bottom: 0.8rem;
    }

    p {
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }

  &_date {
    color: $grey-dark;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0.8rem 0;
  }

  &_desc_link {
    text-decoration: underline;
    &:hover {
      color: $altera-dark;
    }
  }
}

@include respond-to('tablet') {
  .blogs {
    &_container {
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;
      padding: 0 4rem;
    }

    &_nav {
      width: 4rem;
      height: 4rem;
    }
  }
}

@include respond-to('mobile') {
  .blogs {
    &_header {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.5rem;
      margin-bottom: 1.5rem;

      h3 {
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }

    &_navigation {
      align-self: center;
    }

    &_wrapper {
      gap: 1.5rem;
    }

    &_container {
      grid-template-columns: 1fr;
      gap: 0;
      padding: 0;
    }

    &_nav {
      width: 3.2rem;
      height: 3.2rem;

      svg {
        font-size: 1.4rem;
      }
    }

    &_card {
      max-width: 90%;
      margin: 0;
      padding: 1rem;

      img {
        margin-bottom: 1rem;
      }

      h4 {
        font-size: 1.4rem;
      }

      p {
        font-size: 1.1rem;
      }
    }
  }
}
