@mixin btn-base1 {
  padding: 0rem 1rem;
  box-shadow: 0 2px 4px $button-shadow;
  border: 0.1rem solid $light-grey;
}

// Breakpoint mixins
$breakpoints: (
  'mobile': 430px,
  'tablet': 768px,
  'desktop': 1024px,
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}
